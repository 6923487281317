<template>
  <div>
    <iframe :src="publicPath + 'animate/jsk3/index.html'" scrolling="no" class="iframe" frameborder="0" ref="iframe" :height="iframeHeight"></iframe>
  </div>
</template>

<script>

import moment from 'moment'

// 开奖延时 (秒)
// const kOpenDelay = 1200 / 5
// const kOpenDelay = 10
// 倒计时延迟
const kCountdownDelay = 3
// 动画播放时间
const kAnimateSec = 3
// 开奖结果显示时间
// const kResultPageDelay = 15
// 请求间隔
const kRequestInterval = 2

export default {
  name: 'jsk3',
  props: {
    code: String,
    id: String
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      iframeHeight: 870,
      midGame: false,
      timer: null,
      currentOpenCode: null
    }
  },
  mounted () {
    this.$refs.iframe.onload = () => {
      this.iframeSizeFit()
      this.getState()
      // this.test()
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
      this.timer = null
    }
  },
  methods: {
    iframeSizeFit () {
      this.getIframeContentWindow(window => {
        const originWidth = 1125
        const originHeight = 780
        const currentWidth = this.$refs.iframe.offsetWidth
        const zoom = currentWidth / originWidth

        const html = window.document.documentElement
        html.style.zoom = zoom

        this.iframeHeight = originHeight * zoom

        window.ifopen = true
      })
    },
    test () {
      this.getIframeContentWindow(window => {
        window.pcEgg.startRoate()
      })
    },
    getState () {
      this.$api.live(this.code, this.id).then(({ data }) => {
        if (this.currentOpenCode !== data.opencode) {
          this.setupData(data)
        } else {
          var timer = setTimeout(() => {
            this.getState()
            clearTimeout(timer)
          }, kRequestInterval * 1000)
        }
      }).catch(err => {
        this.$message.error(err)
        var timer = setTimeout(() => {
          this.getState()
          clearTimeout(timer)
        }, 3000)
      })
    },
    setupData (data) {
      this.currentOpenCode = data.opencode

      const currentTime = new Date().getTime()
      // const nextTime = (data.next_open_time + kOpenDelay) * 1000
      const nextTime = data.next_open_time * 1000

      const drawIssue = data.next_expect
      const drawTime = moment(nextTime).format('HH:mm')
      const nowDraw = data.expect
      const preDrawCode = data.opencode_arr
      var seconds = (nextTime - currentTime) / 1000
      if (seconds <= 0) {
        seconds = kCountdownDelay
      }

      this.getIframeContentWindow(window => {
        window.k3v.stopVideo({ drawIssue, drawTime, nowDraw, preDrawCode, seconds })
        this.timer = setTimeout(() => {
          clearTimeout(this.timer)
          this.getState()
        }, (seconds + kAnimateSec) * 1000)
      })
    },
    getIframeContentWindow (handler) {
      handler(this.$refs.iframe.contentWindow)
    }
  }
}
</script>

<style lang="scss" scoped>
.iframe {
  width: 100%;
}
</style>
